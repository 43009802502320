import React from 'react'
import { configToBlueprint } from "@flatfile/v2-shims"
import { FlatfileProvider, Space, Workbook } from "@flatfile/react"
import { FlatfileListener } from "@flatfile/listener"
import { autocast } from "@flatfile/plugin-autocast"
import { useFlatfile, useListener } from "@flatfile/react"
import axios from "axios"
import { appConstants } from 'utils/constants.js'


const FFApp = (props) => {
    const { open, openPortal, closePortal } = useFlatfile()

    const listener = FlatfileListener.create((listener) => {
      listener.use(autocast("Monitoring Events"))
      listener.on(
        "job:ready",
        { job: "workbook:submitAction" },
        async ({ context: { jobId, workbookId }, payload  }) => {
          const { data: workbookSheets } = await api.sheets.list({ workbookId })
          let uploadData = []
          for (const [_, element] of workbookSheets.entries()) {
            const { data: records } = await api.records.get(element.id)
            //loop through the records array
            records.records.forEach((record) => {
              //transform the record.values object so that the value of each key is the value of the "value" key in the object
              let transformedRecord = {}
              for (const [key, value] of Object.entries(record.values)) {
                transformedRecord[key] = value.value
              }
              uploadData.push(transformedRecord)
            })
          }
    
          try {
            await api.jobs.ack(jobId, {
              info: "Getting started.",
              progress: 10,
            })
    
            axios.defaults.headers.common["X-CSRF-TOKEN"] = props.authenticityToken
            axios.defaults.headers.common["Content-Type"] = "application/json"
            const response = await axios.post(props.importUrl ? props.importUrl : appConstants.IMPORT_EVENTS_URL, uploadData )
  
            if (response.status === 200) {
              return await api.jobs.complete(jobId, {
                outcome: {
                  message:
                    "Data was successfully submitted."
                },
              })
            } else {
              throw new Error("Failed to submit data.")
            }
    
          } catch (error) {
            console.error("Error:", error.stack)
    
            await api.jobs.fail(jobId, {
              outcome: {
                message: "This job encountered an error.",
              },
            })
          }
        }
      )
    })

    useListener(listener, [])

    return (
      <div>
        <div>
            <button
                className="btn btn-outline-primary btn-lg"  
                onClick={() => {
                    open ? closePortal() : openPortal()
                }}
            >
            {open ? "Close Bulk Upload" : "Initiate Bulk Upload"}
            </button>
        </div>

        <Space
            config={{
                metadata: {
                    sidebarConfig: {
                        showSidebar: true,
                    },
                },
            }}
        >
            <Workbook
              config={
                configToBlueprint(props.schema)
              }
              onSubmit={(sheet) => {
                console.log("onSubmit", { sheet })
              }}
            ></Workbook>
        </Space>
      </div>
    )
}

export default function FFImport(props) {
  return (
      <FlatfileProvider
          publishableKey={props.licenseKey}
          config={{
              displayAsModal: true,
          }}
      >
        <FFApp schema={props.schema} authenticityToken={props.authenticityToken} importUrl={props.importUrl} />
      </FlatfileProvider>
  )
}
